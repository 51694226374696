// Base variables

// Shadows
$enable-shadows: true !default;

// Colors
$primary: 			#FF5722 					!default;
$primary-light: 	lighten($primary, 12%)  	!default;
$primary-dark: 		darken($primary, 12%)  		!default;
$secondary: 		blue 				 		!default;
$secondary-light: 	lighten($secondary, 12%) 	!default;
$secondary-dark: 	darken($secondary, 12%)  	!default;

$theme-colors: map-merge(
	$theme-colors,
	(
		"primary-light": 	$primary-light,
		"primary": 		 	$primary,
		"primary-dark":  	$primary-dark,
		"secondary-light":	$secondary-light,
		"secondary": 		$secondary,
		"secondary-dark": 	$secondary-dark,
	)
);

// Spacers
$spacers: map-merge(
	$spacers,
	(
		6: ($spacer * 6),
		7: ($spacer * 12),
		8: ($spacer * 18),
		9: ($spacer * 24),
		10: ($spacer * 36),
		11: ($spacer * 48),
		12: ($spacer * 72)
	)
);

// Admonition
$admonition-bg: 						$gray-200 	!default;
$admonition-padding-spacer: 			4 			!default;
$admonition-border-left-width-spacer: 	3 			!default;
$admonition-border-left-color: 			$gray-600 	!default;

// TOC
$toc-entry-color:						$gray-400 	!default;
$toc-entry-padding-spacer:				1 			!default;
$toc-entry-lvl-0-margin-top: 			1			!default;
$toc-entry-lvl-0-padding-left: 			2			!default;
$toc-entry-lvl-0-border-bottom-width: 	1px 		!default;
$toc-entry-lvl-1-margin-top: 			0			!default;
$toc-entry-lvl-1-padding-left: 			4			!default;
$toc-entry-lvl-1-border-bottom-width: 	0px 		!default;

// Markdown
$markdown-code-inline-bg:				$gray-300 			!default;
$markdown-code-inline-color:			$secondary 			!default;
$markdown-code-inline-padding-spacer:	1					!default;
$markdown-code-inline-border-radius:	$border-radius-sm 	!default;
$markdown-h12-padding-bottom-spacer: 	2 					!default;
$markdown-h-margin-top-spacer: 			4 					!default;
$markdown-h-margin-bottom-spacer: 		3 					!default;
$markdown-h-font-weight: 				bold 				!default;

// Code
$code-block-bg: 								$gray-900 			!default;
$code-block-padding-spacer: 					3 					!default;
$code-block-border-radius: 						$border-radius-lg 	!default;
$code-block-border-radius: 						$border-radius-lg 	!default;
$code-block-pre-color: 							$gray-300		 	!default;
$code-block-pre-bg: 							$gray-900 			!default;
$code-block-pre-margin-spacer: 					0 					!default;
$code-block-line-number-text-align: 			right 				!default;
$code-block-line-number-padding-right-spacer: 	2 					!default;
$code-block-line-number-color: 					$gray-700 			!default;
