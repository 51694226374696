// Custom app theme
$sidebar-width: 300px;

#page-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    min-width: $sidebar-width;
    max-width: $sidebar-width;
	transition: $transition-base;
	z-index: 1000;
}

#page-sidebar.sidebar-toggle {
    left: -$sidebar-width;
}

#page-content {
	position: absolute;
	top: 0;
	left: $sidebar-width;
	width: calc(100% - #{$sidebar-width});
	transition: $transition-base;
}

#page-content.sidebar-toggle {
    left: 0;
	width: 100%;
}

@media (max-width: map-get($grid-breakpoints, "xl")) {
    #page-sidebar {
		left: -$sidebar-width;
    }
    #page-sidebar.sidebar-toggle {
		left: 0;
    }
	#page-content {
		left: 0;
		width: 100%;
	}
	#page-content.sidebar-toggle {
	    left: 0;
		width: 100%;
	}
}

.netlify-status {
	filter:brightness(70%) grayscale(30%);
}
