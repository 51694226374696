// Base dark variables

// Colors
$primary: 		#FF5722 !default;
$secondary: 	#1DE9B6 !default;

$theme-colors: map-merge(
	$theme-colors,
	(
		"primary": 		$primary,
		"secondary":	$secondary,
	)
);

// Body
$body-bg: $gray-800 !default;
$body-color: $gray-400 !default;
$body-color-strong: $gray-100 !default;

// Components
$border-color:                $gray-700 !default;

// Typography
$hr-border-color:             rgba($white, .2) !default;

// Jumbotron
$jumbotron-bg:                      $gray-600 !default;

// Card
$card-border-color:                 rgba($white, .125) !default;
$card-bg:                           $gray-800 !default;

// Alerts
$alert-bg-level:                    -3 !default;
$alert-border-level:                -2 !default;
$alert-color-level:                 6 !default;

// Nav
$nav-tabs-border-color:             $gray-700 !default;
$nav-tabs-link-hover-border-color:  $gray-700 $gray-700 $nav-tabs-border-color !default;
$nav-tabs-link-active-color:        $body-color !default;
$nav-tabs-link-active-bg:           $body-bg !default;
$nav-tabs-link-active-border-color: $gray-700 $gray-700 $nav-tabs-link-active-bg !default;

// Dropdown
$dropdown-color:                    $body-color !default;
$dropdown-bg:                       $gray-800 !default;
$dropdown-border-color:             rgba($white, .15) !default;
$dropdown-divider-bg:               $gray-700 !default;

$dropdown-link-color:               $body-color !default;
$dropdown-link-hover-color:         lighten($body-color, 5%) !default;
$dropdown-link-hover-bg:            $gray-600 !default;

// List group
$list-group-bg:                     $gray-800 !default;
$list-group-border-color:           rgba($white, .125) !default;

$list-group-hover-bg:               rgba($primary, .33) !default;
$list-group-active-bg:              rgba($primary, .5) !default;

$list-group-disabled-color:         $text-muted !default;
$list-group-disabled-bg:            $list-group-bg !default;

$list-group-action-color:           $body-color !default;
$list-group-action-hover-color:     $list-group-action-color !default;

$list-group-action-active-color:    $body-color !default;
$list-group-action-active-bg:       $primary !default;

// Forms
$input-bg:                              $gray-700 !default;
$input-disabled-bg:                     $gray-800 !default;

$input-color:                           $body-color !default;
$input-border-color:                    $gray-600 !default;

$input-focus-bg:                        $input-bg !default;
$input-focus-color:                     $input-color !default;

// Admonition
$admonition-bg: 				$gray-700 !default;

// TOC
$toc-entry-color:						$gray-600 	!default;

// Markdown
$markdown-code-inline-bg:				$gray-700 !default;

// Code
$pre-color:                         $body-color 	!default;
$code-block-bg: 						$gray-900 	!default;
$code-block-pre-color: 					$body-color !default;
$code-block-pre-bg: 					$gray-900 	!default;
$code-block-line-number-color: 			$gray-700 	!default;
