// Import app variables here
@import "app/variables";

// Import base variables
@import "base/bootstrap/variables";
@import "base/dark/variables";
@import "base/variables";

// Import bootstrap variables
@import "bootstrap/variables";
