// Base theme
a, .list-group-item-action {
	transition: $transition-base;
}

p {	text-align: justify; }

.table th {
	border-top: 0;
}

// Admonition
.admonition {
	padding: 			map-get($spacers, $admonition-padding-spacer);
	background-color: 	$admonition-bg;
	border-left: 		map-get($spacers, $admonition-border-left-width-spacer) solid $admonition-border-left-color;
	border-radius: 		0 $border-radius-lg $border-radius-lg 0;

	p {
		margin: 0;
	}

	.title {
		text-transform: uppercase;
	}
}

@each $color, $value in $theme-colors {
	.admonition-#{$color} {
		border-left-color: 	$value !important;
		background-color: 	rgba($value, .05) !important;

		.title {
			color: $value !important;
		}
	}
}

// TOC
.toc-list{
	list-style: none;
	padding: 	0;
	margin: 	0;
	@extend 	.list-group;
	@extend 	.list-group-flush;
}
.toc-sublist {
	list-style: none;
	padding: 	0;
	margin: 	0;
}
.toc-entry {
	a {
		@extend .list-group-item;
		@extend .list-group-item-action;

		color: 		$toc-entry-color;
		padding: 	map-get($spacers, $toc-entry-padding-spacer);

		.toc-list & {
			margin-top: 			map-get($spacers, $toc-entry-lvl-0-margin-top);
			padding-left: 			map-get($spacers, $toc-entry-lvl-0-padding-left);
			border-bottom-width: 	$toc-entry-lvl-0-border-bottom-width !important;
		}

		.toc-sublist & {
			margin-top: 			map-get($spacers, $toc-entry-lvl-1-margin-top);
			padding-left: 			map-get($spacers, $toc-entry-lvl-1-padding-left);
			border-bottom-width: 	$toc-entry-lvl-1-border-bottom-width !important;
		}
	}
}

// Markdown
.markdown-container {

	> :first-child {
		margin-top: 0 !important;
	}

	h1, h2 {
		border-bottom: 	$hr-border-width solid $hr-border-color;
		padding-bottom: map-get($spacers, $markdown-h12-padding-bottom-spacer);
	}

	h1, h2, h3, h4, h5, h6 {
		font-weight: 	$markdown-h-font-weight;
		margin-top: 	map-get($spacers, $markdown-h-margin-top-spacer);
		margin-bottom: 	map-get($spacers, $markdown-h-margin-bottom-spacer);
	}

	table:not(.rouge-table) {
    	@extend .table;
	}

	code.highlighter-rouge {
		color: 				$markdown-code-inline-color;
		background-color: 	$markdown-code-inline-bg;
		padding: 			map-get($spacers, $markdown-code-inline-padding-spacer);
		padding-bottom: 	0;
		border-radius: 		$markdown-code-inline-border-radius;
	}

	blockquote {
		@extend .admonition;
	}

	img {
		@extend .img-fluid;
		@extend .card;
	}
}

// Code
.highlighter-rouge pre.highlight {
	background-color: 	$code-block-bg;
	padding: 			map-get($spacers, $code-block-padding-spacer);
	border-radius: 		$code-block-border-radius;

	pre {
		color: 				$code-block-pre-color;
		background-color: 	$code-block-pre-bg;
		margin: 			map-get($spacers, $code-block-pre-margin-spacer);
	}

	.rouge-gutter {
		text-align: 	$code-block-line-number-text-align;
		padding-right: 	map-get($spacers, $code-block-line-number-padding-right-spacer);

		.lineno {
			color: 		$code-block-line-number-color;
		}
	}
}
